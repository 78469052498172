<template>
  <page-header-wrapper @back="back">
    <template v-slot:breadcrumb>
      {{ formTitle }}
    </template>
    <template v-slot:title>
      {{ formTitle }}
    </template>
    <div class="bg-white padding-lr" ref="container">
      <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" labelAlign="left" :model="form"
                    :rules="rules">
        <a-collapse v-model="activeKey">
          <a-collapse-panel key="1" :header="$t('商品.基础信息')" class="goods-collapse">
            <a-row>
              <a-col :span="12">
                <!--                <a-form-model-item  :label-col="{ span: 2 }" :wrapper-col="{ span: 18 }" :label="$t('商品.商品名称')" prop="goodsName">-->
                <a-form-model-item :label="$t('商品.商品名称')" prop="goodsName">
                  <a-input v-model="form.goodsName" :maxLength="128" :placeholder="$t('通用.输入.请输入')+$t('商品.商品名称')"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item :label="$t('商品.商品类型')" prop="goodsType">
                  <a-radio-group v-model="form.goodsType" button-style="solid">
                    <a-radio-button v-for="(item, index) in customDict.GoodsTypeEnum" :value="item.type" :key="index">
                      {{ item.name }}
                    </a-radio-button>
                  </a-radio-group>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item :label="$t('商品.商品分类')" prop="categoryId" v-if="form.goodsType===1">
                  <template>
                    <a-cascader :options="categoryList"
                                :field-names="{ label: 'categoryName', value: 'id', children: 'children'}"
                                :placeholder="$t('通用.输入.请输入')+$t('商品.商品分类')"
                                style="width: 100%" v-model="categorySelected" allowClear @change="onCategoryChange">
                    </a-cascader>
                  </template>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item :label="$t('商品.选择运费模板')" v-if="form.goodsType!==2" prop="freightTemplateId">
                  <a-button type="dashed" style="text-align: left; width: 100%" @click="openFreightTemplateSelect"
                            danger>
                    <span style="color: dodgerblue">  {{ this.getFreightName() }}</span>
                  </a-button>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <!--              <a-col :span="12">
                              <a-form-model-item :label="$t('商品.包装单位')" prop="unit">
                                <a-input v-model="form.unit" :maxLength="8" :placeholder="$t('通用.输入.请输入')+$t('商品.包装单位')"/>
                              </a-form-model-item>
                            </a-col>-->
              <a-col :span="12" v-if="formType===2">
                <a-form-model-item :label="$t('商品.商品编号')">
                  <a-input disabled v-model="form.id"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item :label="$t('商品.标签')" prop="labelList" v-if="form.goodsType===2">
                  <a-select mode="multiple" :placeholder="$t('通用.输入.请选择')+$t('商品.标签')" style="width: 100%"
                            v-model="form.labelList" allowClear>
                    <a-select-option v-for="(item, index) in labelList" :value="item.id+''" :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
<!--              <a-col :span="12">
                <a-form-model-item :label="$t('商品.备注')" prop="goodsName" v-if="form.goodsType===2">
                  <a-input v-model="form.remark" :maxLength="128" :placeholder="$t('通用.输入.请输入')+$t('商品.备注')"/>
                </a-form-model-item>
              </a-col>-->
              <a-col :span="12">
                <a-form-model-item :label="$t('商品.套餐')" prop="packageId" v-if="form.goodsType===2">
                  <a-select style="width: 100%" v-model="form.packageId" placeholder="请选择套餐">
                    <a-select-option v-for="(item, index) in packageIdList" :value="item.id" :key="index">
                      {{ item.remark }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item :label="$t('商品.售卖结束时间')" prop="saleEndTime" v-if="form.goodsType===2">
                  <a-date-picker v-model="form.saleEndTime" valueFormat='YYYY-MM-DD HH:mm:ss' show-time
                                 :placeholder="$t('商品.售卖结束时间')" @change="onSaleEndChange" @ok="onSaleEndOk"/>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <!--              <a-col :span="12">
                              <a-form-model-item :label="$t('商品.商品颜色')" prop="color">
                                <a-input type='color' style="width: 50px" v-model="form.color"
                                         :placeholder="$t('通用.输入.请输入')+$t('商品.商品编号')"/>
                              </a-form-model-item>
                            </a-col>-->


            </a-row>

            <a-row>
              <a-col :span="12">
                <a-form-model-item :label="this.$t('商品.商品主图')" prop="picture">
                  <OssUploadSingle v-model="form.picture" type="img" :maxSize="200"></OssUploadSingle>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
<!--                {{form.imgList}}-->
              <a-form-model-item :label="$t('商品.商品轮播图')" prop="imgList">
                <OssUploadMulti v-model="form.imgList" :isArrayData="true" :prefix="goods" type="img"></OssUploadMulti>
              </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item :label="this.$t('商品.商品视频')" prop="video" v-if="form.goodsType===1">
                  <OssUploadSingle v-model="form.video" type="video" :prefix="goods" :maxSize="200"></OssUploadSingle>
                </a-form-model-item>
              </a-col>

              <!--              <a-col :span="12">
                              <a-form-model-item :label="this.$t('商品.商品主图')" prop="picture">
                                <a-upload
                                  name="picture"
                                  listType="picture-card"
                                  :multiple="false"
                                  :show-upload-list="false"
                                  :preview="handlePreview"
                                  :before-upload="beforeOssUpload"
                                  :customRequest="coverImgUpload">
                                  <img
                                    v-if="form.picture"
                                    :src="form.picture"
                                    alt="picture"
                                    style="height: 100px; width: 100px;"/>
                                  <div v-else>
                                    <a-icon :type="loading ? 'loading' : 'plus'"/>
                                    <div class="ant-upload-text">{{ $t('通用.按钮.上传') }}</div>
                                  </div>
                                </a-upload>
                                <span>推荐尺寸 375 x 375</span>
                              </a-form-model-item>
                            </a-col>-->

<!--              <a-col :span="12">
                <a-form-model-item :label="$t('商品.商品轮播图')" prop="imgList">
                  <a-upload
                    name="imgList"
                    v-model="imgList"
                    listType="picture-card"
                    :fileList="imgList"
                    :remove="removeImgCaseFile"
                    class="avatar-uploader"
                    :multiple="false"
                    :show-upload-list="true"
                    @preview="handlePreview"
                    :before-upload="beforeOssUpload"
                    :customRequest="coverImgCaseUploadInfo">
                    <div v-if="imgList.length < 5">
                      <a-icon :type="loading ? 'loading' : 'plus'"/>
                      <div class="ant-upload-text">{{ $t('通用.按钮.上传') }}</div>
                    </div>
                  </a-upload>
                  <span>推荐尺寸 375 x 375</span>
                </a-form-model-item>
              </a-col>-->
            </a-row>
            <a-row>
              <!--              <a-col :span="4">
                              <a-form-model-item  :label-col="{ span: 12 }" :wrapper-col="{ span: 12 }"  :label="$t('商品.选择明星')" prop="starId">
                                <a-button type="dashed" style="text-align: left; width: 100%" @click="openStarSelect" danger>
                                  <span
                                  style="color: dodgerblue">
                                    {{this.getStarName()}}
                                  </span>
                                </a-button>
                              </a-form-model-item>
                            </a-col>
                            <a-col :span="4">
                              <div v-if="form.starId">
                                <img v-if="form.starDetail.picture" :src="form.starDetail.picture" style="width:60px;height:60px;" alt="none"/>
                              </div>
                            </a-col>
                            <a-col :span="4">
                            </a-col>-->
              <a-col :span="12">
                <a-form-model-item :label="$t('商品.品牌')" prop="brandId" v-if="form.goodsType===1">
                  <a-select style="width: 100%" v-model="form.brandId" placeholder="请选择品牌"
                            allowClear
                            :filter-option="false" :show-arrow="false" :not-found-content="null"
                            :default-active-first-option="false"
                            show-search
                            @search="handleBrandSearch"
                            @change="handleBrandChange">
                    <a-select-option v-for="(item, index) in brandList" :value="item.id" :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>


              <!--              <a-col :span="12">
                              <a-form-model-item :label="$t('商品.选择运费模板')" v-if="form.goodsType===1" prop="freightTemplateId">
                                <a-button type="dashed" style="text-align: left; width: 100%" @click="openFreightTemplateSelect" danger>
                                  <span style="color: dodgerblue">  {{this.getFreightName()}}</span>
                                </a-button>
                              </a-form-model-item>
                            </a-col>-->
            </a-row>

          </a-collapse-panel>
          <a-collapse-panel key="2" :header="$t('商品.标签信息')" class="goods-collapse">
            <a-row>
              <a-col :span="12">
                <a-form-model-item v-if="form.goodsType===1" class="margin-bottom-10" :label="$t('商品.是否推荐')"
                                   prop="isRecommend">
                  <a-radio-group v-model="form.isRecommend" button-style="solid">
                    <a-radio-button v-for="(item, index) in this.customDict.TFEnum" :value="item.type" :key="index">
                      {{ item.name }}
                    </a-radio-button>
                  </a-radio-group>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item v-if="form.goodsType===1" class="margin-bottom-10" :label="$t('商品.是否新品')"
                                   prop="isNew">
                  <a-radio-group v-model="form.isNew" button-style="solid">
                    <a-radio-button v-for="(item, index) in this.customDict.TFEnum" :value="item.type" :key="index">
                      {{ item.name }}
                    </a-radio-button>
                  </a-radio-group>
                </a-form-model-item>
              </a-col>
              <!--              <a-col :span="12">
                              <a-form-model-item v-if="form.goodsType===1" class="margin-bottom-10" :label="$t('商品.是否日历展示')" prop="isNew">
                                <a-radio-group v-model="form.isShowCalendar" button-style="solid">
                                  <a-radio-button v-for="(item, index) in this.customDict.TFEnum" :value="item.type" :key="index">
                                    {{ item.name }}
                                  </a-radio-button>
                                </a-radio-group>
                              </a-form-model-item>
                            </a-col>-->

            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item class="margin-bottom-10" :label="$t('商品.是否限购')" prop="isMaxBuy">
                  <!--                  <a-radio-group :disabled="form.goodsType===2" v-model="form.isMaxBuy"  button-style="solid">-->
                  <a-radio-group v-model="form.isMaxBuy" button-style="solid">
                    <a-radio-button v-for="(item, index) in this.customDict.TFEnum" :value="item.type" :key="index">
                      {{ item.name }}
                    </a-radio-button>
                  </a-radio-group>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item v-if="form.isMaxBuy && form.goodsType===1" :label="$t('商品.限购数')" prop="maxBuyNum">
                  <a-input-number :maxLength="10" style="width: 45%" v-model="form.maxBuyNum" @change="limitMaxBuyNum()"
                                  :placeholder="$t('通用.输入.请输入')+$t('商品.限购数')"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item class="margin-bottom-10" :label="$t('商品.商品状态')" prop="status">
                  <a-radio-group v-model="form.status" button-style="solid">
                    <a-radio-button v-for="(item, index) in this.customDict.GoodsOnOffEnum" :value="item.type"
                                    :key="index">
                      {{ item.name }}
                    </a-radio-button>
                  </a-radio-group>
                </a-form-model-item>
              </a-col>
              <a-col v-show="false">
                <a-form-model-item :label="$t('商品.售后方式')" prop="refundTypeList">
                  <a-checkbox-group v-model="form.refundTypeList" @change="onRefundTypeChange">
                    <a-checkbox v-for="(item, index) in this.customDict.GoodsRefundTypeEnum" :label="item.name"
                                :value="item.type" :key="index">
                      {{ item.name }}
                    </a-checkbox>
                  </a-checkbox-group>
                </a-form-model-item>
              </a-col>
            </a-row>

            <!--            <a-row>
                          <a-col :span="12">
                            <a-form-model-item :label="$t('商品.售卖开始时间')" prop="saleStartTime">
                              <a-date-picker v-model="form.saleStartTime" valueFormat='YYYY-MM-DD HH:mm:ss' show-time
                                             :placeholder="$t('商品.售卖开始时间')" @change="onSaleStartChange" @ok="onSaleStartOk"/>
                            </a-form-model-item>
                          </a-col>
                          <a-col :span="12">
                            <a-form-model-item :label="$t('商品.售卖结束时间')" prop="saleEndTime">
                              <a-date-picker v-model="form.saleEndTime" valueFormat='YYYY-MM-DD HH:mm:ss' show-time
                                             :placeholder="$t('商品.售卖结束时间')" @change="onSaleEndChange" @ok="onSaleEndOk"/>
                            </a-form-model-item>
                          </a-col>
                        </a-row>-->
            <!--            <a-row>
                          <a-col :span="12">
                            <a-form-model-item :label="$t('商品.标签')" prop="labelList">
                              <a-select mode="multiple"  :placeholder="$t('通用.输入.请选择')+$t('商品.标签')" style="width: 100%" v-model="form.labelList" allowClear>
                                <a-select-option v-for="(item, index) in labelList" :value="item.id+''" :key="index">
                                  {{ item.name }}
                                </a-select-option>
                              </a-select>
                            </a-form-model-item>
                          </a-col>
                          <a-col :span="12">
                            <a-form-model-item :label="$t('商品.售卖区域')" prop="prodAreaList">
                              <a-select mode="multiple"  :placeholder="$t('通用.输入.请选择')+$t('商品.售卖区域')" style="width: 100%" v-model="form.prodAreaList" allowClear>
                                <a-select-option v-for="(item, index) in this.customDict.UserNationEnum" :value="item.type" :key="index">
                                  {{ item.name }}
                                </a-select-option>
                              </a-select>
                            </a-form-model-item>
                          </a-col>
                        </a-row>-->

          </a-collapse-panel>
          <a-collapse-panel key="3" :header="$t('商品.规格信息')" class="goods-collapse">
            <a-row>
              <a-col :span="12">
                <a-form-model-item :label="$t('商品.市场价')" prop="marketPrice">
                  <a-input v-model="form.marketPrice" @change="limitMarketPrice()" suffix="¥"
                           :placeholder="$t('通用.输入.请输入')+$t('商品.市场价')"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item :label="$t('商品.商品价格')" prop="price">
                  <a-input v-model="form.price" @change="limitPrice()" suffix="¥"
                           :placeholder="$t('通用.输入.请输入')+$t('商品.商品价格')"/>
                </a-form-model-item>
              </a-col>
              <!--              <a-col :span="12">
                              <a-form-model-item :label="$t('商品.成本价')" prop="costPrice">
                                <a-input v-model="form.costPrice" @change="limitCostPrice()" suffix="¥"
                                         :placeholder="$t('通用.输入.请输入')+$t('商品.成本价')"/>
                              </a-form-model-item>
                            </a-col>-->
            </a-row>
            <!--            <a-row>
                          <a-col :span="12">
                            <a-form-model-item :label="$t('商品.虚拟销量')" prop="virtualSalesNum">
                              <a-input v-model="form.virtualSalesNum" @change="limitVirtualSalesNum()"
                                       :placeholder="$t('通用.输入.请输入')+$t('商品.虚拟销量')"/>
                            </a-form-model-item>
                          </a-col>
                          <a-col :span="12">
                            <a-form-model-item :label="$t('商品.是否展示销量')" prop="isShowSalesNum">
                              <a-radio-group button-style="solid" v-model="form.isShowSalesNum">
                                <a-radio-button v-for="(item, index) in this.customDict.TFEnum" :value="item.type" :key="index">
                                  {{ item.name }}
                                </a-radio-button>
                              </a-radio-group>
                            </a-form-model-item>
                          </a-col>
                        </a-row>-->
            <a-row>
              <a-col :span="12">
                <a-form-model-item class="margin-bottom-10" :label="$t('商品.是否多规格')" prop="isMultipleSpec"
                                   v-if="form.goodsType===1">
                  <a-radio-group :disabled="form.goodsType===2" button-style="solid" v-model="form.isMultipleSpec"
                                 @change="multipleSpecChange()">
                    <a-radio-button v-for="(item, index) in this.customDict.TFEnum" :value="item.type" :key="index">
                      {{ item.name }}
                    </a-radio-button>
                  </a-radio-group>
                </a-form-model-item>
              </a-col>
              <a-col :span="12" v-if="form.isMultipleSpec">
                <a-form-model-item class="margin-bottom-10" :label="$t('商品.是否允许合并购买')" prop="isAllowMerge"
                                   v-if="form.goodsType===1">
                  <a-radio-group button-style="solid" v-model="form.isAllowMerge">
                    <a-radio-button v-for="(item, index) in this.customDict.TFEnum" :value="item.type" :key="index">
                      {{ item.name }}
                    </a-radio-button>
                  </a-radio-group>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <div v-if="!form.isMultipleSpec">
                <!--                <div>-->
                <a-col :span="12">
                  <a-form-model-item :label="$t('商品.活动价格')" prop="activityPrice" v-if="form.goodsType===1">
                    <a-input v-model="form.activityPrice" @change="limitActivityPrice()" suffix="¥"
                             :placeholder="$t('通用.输入.请输入')+$t('商品.活动价格')"/>
                  </a-form-model-item>
                </a-col>
                <!--
                            <a-col >
                              <a-form-model-item :label="$t('商品.秒杀价格')" prop="seckillPrice">
                                <a-input v-model="form.seckillPrice" @change="limitSeckillPrice()" suffix="¥"
                                         :placeholder="$t('通用.输入.请输入')+$t('商品.秒杀价格')"/>
                              </a-form-model-item>
                            </a-col>
                            <a-col >
                              <a-form-model-item :label="$t('商品.团购价格')" prop="groupPrice">
                                <a-input v-model="form.groupPrice" @change="limitGroupPrice()" suffix="¥"
                                         :placeholder="$t('通用.输入.请输入')+$t('商品.团购价格')"/>
                              </a-form-model-item>
                              <a-form-model-item :label="$t('商品.会员价格')" prop="memberPrice">
                                <a-input v-model="form.memberPrice" @change="limitMemberPrice()" suffix="¥"
                                         :placeholder="$t('通用.输入.请输入')+$t('商品.会员价格')"/>
                              </a-form-model-item>
                            </a-col>

                            -->

                <a-col :span="12">
                  <a-form-model-item :label="$t('商品.商品重量')" prop="weight"
                                     v-if="form.goodsType===1 && !form.isMultipleSpec">
                    <a-input v-model="form.weight" @change="limitWeight()" suffix="g"
                             :placeholder="$t('通用.输入.请输入')+$t('商品.商品重量')"/>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item :label="$t('商品.商品库存')" prop="stock">
                    <a-input-number v-model="form.stock" style="width: 45%" :maxLength="9" @change="limitStock()"
                                    :placeholder="$t('通用.输入.请输入')+$t('商品.商品库存')"/>
                  </a-form-model-item>
                </a-col>
              </div>
            </a-row>
            <a-row>
              <a-col span="2" v-if="form.isMultipleSpec  && form.goodsType===1">
                <a-button v-if="form.specList.length < 4" :disabled="specDisable" type="primary" style="width: 100%"
                          @click="addSpec(1)"
                          class="margin-bottom">
                  <a-icon type="plus"/>
                  {{ $t('商品.添加规格') }}
                </a-button>
              </a-col>
              <a-col span="2" v-if="specDisable && form.isMultipleSpec && form.goodsType===1">
                <a-button v-if="form.specList.length < 4" :disabled="!specDisable" type="primary" style="width: 100%"
                          @click="editSpec()"
                          class="margin-bottom">
                  <a-icon type="edit"/>
                  {{ $t('商品.修改规格') }}
                </a-button>
              </a-col>
              <a-col :span="20">
              </a-col>
            </a-row>
            <div v-if="form.isMultipleSpec && form.goodsType===1">
              <div class="box" v-for="(item, id) in form.specList" :key="id">
                <a-row>
                  <a-popconfirm title="确定要删除此规格吗?" ok-text="确定" cancel-text="取消" @confirm="delSpec(id)">
                    <a-icon type="close-circle" class="delSpec"/>
                  </a-popconfirm>
                  <a-col :span="18">
                    <a-form-model-item layout="inline" :label="$t('商品.规格.规格名称')" :prop="`specList.${id}.specName`"
                                       :rules='rules.specName'>
                      <a-input v-model="item.specName" :placeholder="$t('通用.输入.请输入')"/>
                    </a-form-model-item>
                  </a-col>
                  <!--              <a-col :span="18">
                                  <a-form-model-item layout="inline" label="排序">
                                    <a-input-number v-model="item.sort" :placeholder="$t('通用.输入.请输入')"/>
                                  </a-form-model-item>
                                </a-col>-->
                  <a-col :span="18">
                    <a-form-model-item layout="inline" :label="$t('商品.规格.是否有规格图片')" v-show="!isSpecPic||item.isHaveImg">
                      <a-radio-group button-style="solid" v-model="item.isHaveImg" @change="isSpecPicChange"
                                     :disabled="specDisable">
                        <a-radio-button v-for="(visibleItem, v_index) in customDict.TFEnum" :value="visibleItem.type"
                                        :key="v_index">
                          {{ visibleItem.name }}
                        </a-radio-button>
                      </a-radio-group>
                    </a-form-model-item>
                  </a-col>
                </a-row>

                <a-row>
                  <a-col :span="20" :offset="2">
                    <div class="margin-bottom-10">
                      <a-button type="primary" @click='addSpecValue(item)' :disabled="specDisable">
                        {{ $t('商品.规格.添加规格值') }}
                      </a-button>
                    </div>
                    <a-table :data-source="item.specValueList" :columns="specValueColumns" rowKey="id"
                             :pagination="false"
                             bordered>
                      <div slot="specValueName" slot-scope="text, record">
                        <a-input :disabled="specDisable" v-model="record.specValueName" :maxLength="32"
                                 placeholder="请输入规格" @blur="validatorSpecValue(item)"/>
                      </div>
                      <div slot="isValueVisible" slot-scope="text, record">
                        <a-radio-group button-style="solid" v-model="record.isVisible"
                                       :disabled="getLimitOne(record.index,item.specValueList)">
                          <a-radio-button v-for="(visibleVauleItem, v_i_index) in customDict.TFEnum"
                                          :value="visibleVauleItem.type"
                                          :key="v_i_index">
                            {{ visibleVauleItem.name }}
                          </a-radio-button>
                        </a-radio-group>
                      </div>
                      <div v-show="item.isHaveImg" slot="valuePicture" slot-scope="text, record">
                        <a-upload :disabled="specDisable"
                                  name="valuePicture"
                                  listType="picture-card"
                                  :multiple="false"
                                  :show-upload-list="false"
                                  :preview="handlePreview"
                                  :before-upload="beforeOssUpload"
                                  :customRequest="e=>coverSpecValueImgUpload(e,record)">
                          <img
                            v-if="record.picture"
                            :src="record.picture"
                            alt="picture"
                            style="height: 102px; width: 102px;"/>
                          <div v-else>
                            <a-icon :type="loading ? 'loading' : 'plus'"/>
                            <div class="ant-upload-text">{{ $t('通用.按钮.上传') }}</div>
                          </div>
                        </a-upload>
                      </div>
                      <div slot="action" slot-scope="text, record">
                        <a-popconfirm title="确定要删除此规格值吗？" ok-text="确定" cancel-text="取消" v-if="!specDisable"
                                      @confirm="delSpecValue(item,record)">
                          <a href="javascript:void(0)" style="color:red;">删除</a>
                        </a-popconfirm>
                      </div>
                    </a-table>
                    <span style="color: red;" v-if="item.specErrorMessage">{{ item.specErrorMessage }}</span>
                  </a-col>
                </a-row>
              </div>
              <!--    sku列表        -->
              <div>
                <a-row>
                  <a-col :span="20" :offset="2">
                    <a-button class='margin-tb' type="primary" @click="generateSkus" :disabled="specDisable">生成sku
                    </a-button>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="20" :offset="2">
                    <a-table :loading="skuLoading" :data-source="form.skuList" :columns="skuColumns" rowKey="id"
                             :pagination="false" bordered>
                      <div slot="skuPrice" slot-scope="text, record">
                        <a-input v-model="record.price" :placeholder="$t('通用.输入.请输入')+$t('商品.商品价格')"
                                 @blur.native.capture="validatorSku"/>
                      </div>
                      <div slot="skuMemberPrice" slot-scope="text, record">
                        <a-input v-model="record.memberPrice" :placeholder="$t('通用.输入.请输入')+$t('商品.会员价格')"/>
                      </div>
                      <div slot="skuSeckillPrice" slot-scope="text, record">
                        <a-input v-model="record.seckillPrice" :placeholder="$t('通用.输入.请输入')+$t('商品.秒杀价格')"/>
                      </div>
                      <div slot="skuActivityPrice" slot-scope="text, record">
                        <a-input v-model="record.activityPrice" :placeholder="$t('通用.输入.请输入')+$t('商品.活动价格')"
                                 @blur.native.capture="validatorSku"/>
                      </div>
                      <div slot="skuGroupPrice" slot-scope="text, record">
                        <a-input v-model="record.groupPrice" :placeholder="$t('通用.输入.请输入')+$t('商品.团购价格')"/>
                      </div>
                      <div slot="skuCode" slot-scope="text, record">
                        <a-input v-model="record.skuCode" :placeholder="$t('通用.输入.请输入')+$t('商品.SKU编码')"
                                 @blur.native.capture="validatorSku"/>
                      </div>
                      <div slot="skuWeight" slot-scope="text, record">
                        <a-input v-model="record.weight" :placeholder="$t('通用.输入.请输入')+$t('商品.商品重量')"
                                 @blur.native.capture="validatorSku"/>
                      </div>
                      <div slot="skuStock" slot-scope="text, record">
                        <a-input-number v-model="record.stock" :placeholder="$t('通用.输入.请输入')+$t('商品.商品库存')"
                                        @blur.native.capture="validatorSku"/>
                      </div>
                      <template slot="skuPicture" slot-scope="text, record">
                        <div>
                          <img v-if="record.picture" :src="record.picture" style="width:60px;height:62px;" alt="none"/>
                        </div>
                      </template>
                    </a-table>
                    <span style="color: red;" v-if="skuErrorMessage">{{ skuErrorMessage }}</span>
                  </a-col>
                </a-row>
              </div>
            </div>
          </a-collapse-panel>
          <a-collapse-panel key="4" :header="$t('商品.商品详情')" class="goods-collapse" v-if="form.goodsType===1">
            <a-row style="height: 30px">
              <a-col></a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-model-item :label-col="{span: 2}" :wrapper-col="{span: 20}" :label="$t('商品.商品详情')"
                                   prop="description">
                  <editor v-model="form.description" :placeholder="$t('通用.输入.请输入')+$t('商品.商品详情')"/>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-collapse-panel>
        </a-collapse>

        <a-divider></a-divider>
        <div class="bottom-control">
          <a-space>
            <a-button type="primary" :loading="submitLoading" @click="submitForm">
              {{ $t('通用.按钮.保存') }}
            </a-button>
            <a-button type="dashed" @click="cancel">
              {{ $t('通用.按钮.取消') }}
            </a-button>
          </a-space>
        </div>
        <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
          <img alt="example" style="width: 100%" :src="previewUrl"/>
        </a-modal>
        <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
          <video style="width: 100%" :src="previewUrl" ref="myVideo"
                 :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
        </a-modal>
      </a-form-model>
      <star-select-form ref="StarSelectForm" @select="indexStarModalSelect"></star-select-form>
      <radio-freight-select-form ref="RadioFreightSelectForm"
                                 @freightSelect="indexFreightTemplateSelect"></radio-freight-select-form>

    </div>
  </page-header-wrapper>

</template>

<script>
import {allBrand, listBrand} from '@/api/goods/brand'
import {getGoods, addGoods, updateGoods} from '@/api/goods/goods'
import {listCategory} from "@/api/goods/category";
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject, uploadOss} from "@/api/tool/oss";
import Editor from '@/components/Editor'
import StarSelectForm from "@/views/star/star/modules/SelectForm";
import {translation} from "@/api/tool/common";
import RadioFreightSelectForm from "@/views/mall/template/RadioSelectForm";
import events from "@/components/MultiTab/events";
import bus from "@/utils/bus";
import {allLabel} from "@/api/goods/label";
import {pageSys, listSys, delSys, updateReservation} from '@/api/thali/sys'
/*import OssMultiUploadSingle from '@/views/goods/goods/modules/OssMultiUploadSingle.vue'
import OssMultiUpload from '@/views/goods/goods/modules/OssMultiUpload.vue'*/
import OssUploadSingle from "@/components/OssUploadSingle";
import OssUploadMulti from "@/components/OssUploadMulti";
export default {
  name: 'CreateForm',
  props: {},
  components: {
    Editor,
    StarSelectForm,
    RadioFreightSelectForm,
    CustomDictTag,
    // OssMultiUpload, OssMultiUploadSingle,
    OssUploadSingle, OssUploadMulti,
  },
  data() {
    return {
      tabIndex: 1,
      categoryList: [{id: "0", categoryName: '', children: []}],
      brandList: [],
      packageIdList: [],
      labelList: [],
      /*是否有规格图片*/
      isSpecPic: false,
      /*分类的回显*/
      categorySelected: [],
      labelCol: {span: 4},
      activeKey: [1, 2, 3, 4],
      wrapperCol: {span: 16},
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      skuLoading: false,
      imgList: [],
      skuErrorMessage: undefined,
      errorMessage: undefined,
      localeColumns: [
        {
          title: this.$t('通用.文本.语言'),
          dataIndex: 'locale',
          key: 'locale',
          scopedSlots: {customRender: 'locale'}
        }, {
          title: this.$t('商品.商品名称'),
          dataIndex: 'name',
          scopedSlots: {customRender: 'name'},
          key: 'name'
        }, {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'action',
          key: 'action',
          slots: {title: 'addbtn', customRender: 'brandName'},
          scopedSlots: {customRender: 'action'}
        }
      ],
      specValueColumns: [
        {
          title: this.$t('商品.规格.规格值'),
          dataIndex: 'specValueName',
          key: 'specValueName',
          scopedSlots: {customRender: 'specValueName'}
        }, {
          title: this.$t('商品.规格.是否可见'),
          dataIndex: 'isVisible',
          key: 'isVisible',
          scopedSlots: {customRender: 'isValueVisible'}
        }, {
          title: this.$t('商品.规格.规格图片'),
          dataIndex: 'picture',
          key: 'picture',
          scopedSlots: {customRender: 'valuePicture'}
        }, {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'action',
          key: 'action',
          slots: {title: 'addbtn', customRender: 'name'},
          scopedSlots: {customRender: 'action'}
        }
      ],
      skuColumns: [
        {
          title: this.$t('商品.sku.名称'),
          dataIndex: 'skuName',
          key: 'skuName',
        }, {
          title: this.$t('商品.sku.编码'),
          dataIndex: 'skuCode',
          key: 'skuCode',
          scopedSlots: {customRender: 'skuCode'}
        }, {
          title: this.$t('商品.sku.价格'),
          dataIndex: 'price',
          key: 'price',
          scopedSlots: {customRender: 'skuPrice'}
        }, {
          title: this.$t('商品.sku.活动价格'),
          dataIndex: 'activityPrice',
          key: 'activityPrice',
          scopedSlots: {customRender: 'skuActivityPrice'}
        },
        /* {
           title: '秒杀价格',
           dataIndex: 'seckillPrice',
           key: 'seckillPrice',
           scopedSlots: {customRender: 'skuSeckillPrice'}
         }, {
           title: '团购价格',
           dataIndex: 'groupPrice',
           key: 'groupPrice',
           scopedSlots: {customRender: 'skuGroupPrice'}
         }, {
           title: '会员价格',
           dataIndex: 'memberPrice',
           key: 'memberPrice',
           scopedSlots: {customRender: 'skuMemberPrice'}
         }, */
        {
          title: this.$t('商品.sku.库存'),
          dataIndex: 'stock',
          key: 'stock',
          scopedSlots: {customRender: 'skuStock'}
        }, {
          title: this.$t('商品.sku.重量'),
          dataIndex: 'weight',
          key: 'weight',
          scopedSlots: {customRender: 'skuWeight'}
        }, {
          title: this.$t('商品.sku.图片'),
          dataIndex: 'picture',
          key: 'picture',
          scopedSlots: {customRender: 'skuPicture'}
        }
      ],
      specDisable: false,
      /*是否发生了规格变动如果有则保存到后台时会先执行删除商品操作 后执行添加商品操作  如果没有则 直接修改*/
      isChangeSpec: false,
      // 表单参数
      form: {
        id: null,
        isChangeSpec: false,
        isMaxBuy: null,
        specList: [],
        labelList: [],
        prodAreaList: [],
        localeList: [],
        skuList: [],
        isMultipleSpec: null,
        isAllowMerge: null,
        maxBuyNum: null,
        saleStartTime: null,
        saleEndTime: null,
        imgList: [],
        starDetail: null,
        freightTemplateDetail: null,
        goodsCode: null,
        goodsName: null,
        goodsType: 1,
        weight: null,
        starId: null,
        unit: null,
        picture: null,
        color: null,
        refundTypeList: [1, 2],
        imgIdArray: null,
        categoryId: null,
        shopId: null,
        shopCategoryId: null,
        brandId: null,
        packageId: null,
        price: null,
        marketPrice: null,
        costPrice: 0,
        virtualSalesNum: 0,
        activityPrice: null,
        seckillPrice: null,
        memberPrice: null,
        groupPrice: null,
        freightTemplateId: null,
        stock: null,
        alarmStock: null,
        description: null,
        isRecommend: null,
        isNew: null,
        isShowCalendar: null,
        isShowSalesNum: true,
        sort: null,
        parameter: null,
        prodArea: null,
        remark: null,
        video: null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        goodsCode: [{required: true, message: this.$t('商品.商品编号') + this.$t('通用.文本.不能为空'), trigger: 'blur'}],
        packageId: [{required: true, message: this.$t('商品.商品编号') + this.$t('通用.文本.不能为空'), trigger: 'blur'}],
        goodsName: [{required: true, message: this.$t('商品.商品名称') + this.$t('通用.文本.不能为空'), trigger: 'blur'}],
        localeList: [{required: true, message: this.$t('商品.商品名称') + this.$t('通用.文本.不能为空'), trigger: 'blur'}],
        goodsType: [{required: true, message: this.$t('商品.商品类型') + this.$t('通用.文本.不能为空'), trigger: 'blur'}],
        specName: [{required: true, message: this.$t('商品.规格.规格名称') + this.$t('通用.文本.不能为空'), trigger: 'blur'}],
        localeGoodsName: [{required: true, message: this.$t('商品.商品名称') + this.$t('通用.文本.不能为空'), trigger: 'blur'}],
        isMaxBuy: [{required: true, message: this.$t('商品.是否限购') + this.$t('通用.文本.不能为空'), trigger: 'blur'}],
        maxBuyNum: [{required: true, message: this.$t('商品.限购数') + this.$t('通用.文本.不能为空'), trigger: 'blur'}],
        weight: [{required: true, message: this.$t('商品.商品重量') + this.$t('通用.文本.不能为空'), trigger: 'blur'}],
        picture: [{required: true, message: this.$t('商品.商品主图') + this.$t('通用.文本.不能为空'), trigger: 'blur'}],
        categoryId: [{required: true, message: this.$t('商品.商品分类') + this.$t('通用.文本.不能为空'), trigger: 'blur'}],
        starId: [{required: true, message: this.$t('商品.明星') + this.$t('通用.文本.不能为空'), trigger: 'change'}],
        price: [{required: true, message: this.$t('商品.商品价格') + this.$t('通用.文本.不能为空'), trigger: 'blur'}],
        memberPrice: [{required: true, message: this.$t('商品.会员价格') + this.$t('通用.文本.不能为空'), trigger: 'blur'}],
        groupPrice: [{required: true, message: this.$t('商品.团购价格') + this.$t('通用.文本.不能为空'), trigger: 'blur'}],
        activityPrice: [{required: true, message: this.$t('商品.活动价格') + this.$t('通用.文本.不能为空'), trigger: 'blur'}],
        seckillPrice: [{required: true, message: this.$t('商品.秒杀价格') + this.$t('通用.文本.不能为空'), trigger: 'blur'}],
        saleEndTime: [{required: true, message: this.$t('商品.售卖结束时间') + this.$t('通用.文本.不能为空'), trigger: 'change'}],
        saleStartTime: [{required: true, message: this.$t('商品.售卖开始时间') + this.$t('通用.文本.不能为空'), trigger: 'change'}],
        prodAreaList: [{required: true, message: this.$t('商品.售卖区域') + this.$t('通用.文本.不能为空'), trigger: 'change'}],
        marketPrice: [{required: true, message: this.$t('商品.市场价') + this.$t('通用.文本.不能为空'), trigger: 'blur'}],
        costPrice: [{required: true, message: this.$t('商品.成本价') + this.$t('通用.文本.不能为空'), trigger: 'blur'}],
        virtualSalesNum: [{required: true, message: this.$t('商品.虚拟销量') + this.$t('通用.文本.不能为空'), trigger: 'blur'}],
        freightTemplateId: [{required: true, message: this.$t('商品.运费模板') + this.$t('通用.文本.不能为空'), trigger: 'change'}],
        color: [{required: true, message: this.$t('商品.商品颜色') + this.$t('通用.文本.不能为空'), trigger: 'blur'}],
        stock: [{required: true, message: this.$t('商品.商品库存') + this.$t('通用.文本.不能为空'), trigger: 'blur'}],
        isRecommend: [{required: true, message: this.$t('商品.是否推荐') + this.$t('通用.文本.不能为空'), trigger: 'blur'}],
        isMultipleSpec: [{required: true, message: this.$t('商品.是否多规格') + this.$t('通用.文本.不能为空'), trigger: 'blur'}],
        isAllowMerge: [{required: true, message: this.$t('商品.是否允许合并购买') + this.$t('通用.文本.不能为空'), trigger: 'blur'}],
        isNew: [{required: true, message: this.$t('商品.是否新品') + this.$t('通用.文本.不能为空'), trigger: 'blur'}],
        isShowCalendar: [{required: true, message: this.$t('商品.是否日历展示') + this.$t('通用.文本.不能为空'), trigger: 'blur'}],
        isShowSalesNum: [{required: true, message: this.$t('商品.是否展示销量') + this.$t('通用.文本.不能为空'), trigger: 'blur'}],
        sort: [{required: true, message: this.$t('商品.排序') + this.$t('通用.文本.不能为空'), trigger: 'blur'}],
      }
    };
  },
  filters: {},
  created() {
    this.getCategoryList()
    this.getBrandList()
    this.getLabelList()
    this.getPackageIdList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
    this.form.id = this.$route.query.id
    let copy = this.$route.query.copy
    if (this.form.id) {
      this.handleUpdate(this.form.id, copy)
    } else {
      this.handleAdd()
    }
  },
  methods: {
    container() {
      return this.$refs.container;
    },
    back() {
      let pages = this.$store.pages;
      pages.indexOf(this.$route)
      events.$emit('close', pages.indexOf(this.$route))
      // 去更新列表页的数据
      bus.$emit('updateGoodsList')
      this.$router.push({path: '/goods/goods', replace: true, query: {}})
    },
    /*锚点*/
    getCurrentAnchor() {
      return '#components-anchor-demo-static';
    },
    changeTab(index, type) {
      if (type === 'next') {
        let isValid = false
        this.$refs.form.validate(valid => {
          isValid = valid;
        });
        if (!isValid) {
          console.log('有必填项未填');
          return
        }
        this.tabIndex = index + 1;
      } else if (type === 'back') {
        this.tabIndex = index - 1
      }
    },
    isSpecPicChange(e) {
      //其他规格就不能有图片了
      this.isSpecPic = e.target.value
    },
    generateSkus() {
      this.skuLoading = true;
      this.form.skuList = [];
      let skuValueList = [];
      //拿到所有的sku值
      this.form.specList.forEach(spec => {
        skuValueList.push(spec.specValueList);
      });
      //递归,求笛卡尔积
      let sku = this.cartesianProduct(skuValueList);
      //把这些sku 组装一下放到skuList里
      let skuList = [];
      let index = 1;
      sku.forEach(e => {
        let name = '';
        let skuPic = '';
        let specValueIds = '';
        e.forEach(j => {
          name += j.specValueName + "  ";

          specValueIds += j.id + ":";
          if (j.picture) {
            skuPic = j.picture
          }
        });
        specValueIds = specValueIds.substring(0, specValueIds.length - 1);
        const newData = {
          id: index,
          skuName: name,
          skuCode: null,
          price: 0,
          memberPrice: 0,
          groupPrice: 0,
          activityPrice: 0,
          seckillPrice: 0,
          picture: skuPic,
          specValueIds: specValueIds,
          stock: 0,
          weight: 0,
        };
        skuList.push(newData);
        index++;
      });
      this.form.skuList = skuList;
      this.skuLoading = false;
      console.log('sku', this.form.skuList)
    },

    cartesianProduct(arr) {
      if (arr.length === 0) {
        return [[]];
      }
      const result = [];
      const rest = this.cartesianProduct(arr.slice(1));
      for (let i = 0; i < arr[0].length; i++) {
        for (let j = 0; j < rest.length; j++) {
          result.push([arr[0][i], ...rest[j]]);
        }
      }
      return result;
    },
    onClose() {
      this.open = false
      this.reset()
      this.back()
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
      this.back()
    },
    onRefundTypeChange(value) {
      console.log('refundtypevalue', value)
      console.log('refundtypevalue', this.form.refundType)

    },

    openStarSelect() {
      this.$refs.StarSelectForm.openStar(this.form.starId);
    },
    indexStarModalSelect(record) {
      console.log('选择了', record)
      this.form.starId = record.id;
      this.form.starDetail = record
    },


    openFreightTemplateSelect() {
      this.$refs.RadioFreightSelectForm.openList(this.form.freightTemplateId);
    },
    indexFreightTemplateSelect(record) {
      console.log('选择了', record)
      this.form.freightTemplateId = record.id;
      this.form.freightTemplateDetail = record
    },

    // 表单重置
    reset() {
      this.formType = 1
      this.categorySelected = [];
      this.specDisable = false;
      this.isChangeSpec = false;
      this.form = {
        id: null,
        isMaxBuy: false,
        isChangeSpec: false,
        specList: [],
        labelList: [],
        prodAreaList: [],
        localeList: [],
        skuList: [],
        isMultipleSpec: false,
        isAllowMerge: true,
        freightTemplateDetail: null,
        maxBuyNum: null,
        saleStartTime: null,
        saleEndTime: null,
        goodsCode: null,
        goodsName: null,
        goodsType: 1,
        weight: null,
        unit: null,
        starId: null,
        imgList: [],
        picture: null,
        imgIdArray: null,
        categoryId: null,
        shopId: null,
        color: '#FFFFFF',
        refundTypeList: [1, 2],
        shopCategoryId: null,
        brandId: null,
        packageId: null,
        price: 0,
        marketPrice: 0,
        costPrice: 0,
        virtualSalesNum: 0,
        activityPrice: 0,
        seckillPrice: 0,
        memberPrice: 0,
        groupPrice: 0,
        freightTemplateId: null,
        stock: 0,
        alarmStock: null,
        description: null,
        isRecommend: false,
        isNew: false,
        isShowCalendar: false,
        isShowSalesNum: true,
        sort: null,
        realSales: null,
        parameter: null,
        prodArea: null,
        remark: null,
      };

    },
    onSaleStartChange(value, dateString) {
    },
    onSaleStartOk(value, dateString) {
    },

    onSaleEndChange(value, dateString) {
    },
    onSaleEndOk(value, dateString) {
    },

    /*一旦单规格变多规格, 或者多规格变单规格, 清空所有的sku 和spec*/
    multipleSpecChange() {
      this.form.specList = [];
      this.form.skuList = [];
    },


    /*x限制只能输入数字,并限定最大值*/
    limitWeight() {
      // console.log(i);
      let val = this.form.weight.replace(/^\D*([0-9]\d*\.?\d{0,3})?.*$/, '$1');
      if (val == null || val == undefined || val == '' || val < 0) {
        val = 0
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.weight = val
    },
    limitPrice() {
      // console.log(i);
      let val = this.form.price.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1');
      if (val == null || val == undefined || val == '' || val < 0) {
        val = ''
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.price = val
    },
    limitActivityPrice() {
      // console.log(i);
      let val = this.form.activityPrice.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1');
      if (val == null || val == undefined || val == '' || val < 0) {
        val = ''
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.activityPrice = val
    },
    limitSeckillPrice() {
      // console.log(i);
      let val = this.form.seckillPrice.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1');
      if (val == null || val == undefined || val == '' || val < 0) {
        val = ''
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.seckillPrice = val
    },
    limitMemberPrice() {
      // console.log(i);
      let val = this.form.memberPrice.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1');
      if (val == null || val == undefined || val == '' || val < 0) {
        val = ''
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.memberPrice = val
    },
    limitGroupPrice() {
      // console.log(i);
      let val = this.form.groupPrice.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1');
      if (val == null || val == undefined || val == '' || val < 0) {
        val = ''
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.groupPrice = val
    },

    handleChangeSpec(e) {
      debugger
      console.log('选中品牌', e)
      this.brandId = e
    },

    limitStock() {
      // console.log(i);
      let val = this.form.stock.replace(/^\D*([0-9]\d*)?.*$/, '$1');
      if (val == null || val == undefined || val == '' || val < 1) {
        val = 1
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.stock = val
    },
    limitMarketPrice() {
      // console.log(i);
      let val = this.form.marketPrice.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1');
      if (val == null || val == undefined || val == '' || val < 0) {
        val = ''
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.marketPrice = val
    },
    limitCostPrice() {
      // console.log(i);
      let val = this.form.costPrice.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1');
      if (val == null || val == undefined || val == '' || val < 0) {
        val = ''
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.costPrice = val
    },

    limitVirtualSalesNum() {
      // console.log(i);
      let val = this.form.virtualSalesNum.replace(/^\D*([0-9]\d*)?.*$/, '$1');
      if (val == null || val == undefined || val == '' || val < 0) {
        val = ''
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.virtualSalesNum = val
    },

    limitMaxBuyNum() {
      // console.log(i);
      let val = this.form.maxBuyNum.replace(/^\D*([0-9]\d*)?.*$/, '$1');
      if (val == null || val == undefined || val == '' || val < 0) {
        val = 1
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.maxBuyNum = val
    },

    initLocale() {
      //初始化多语言
      let localeList = [];
      let i = 1;
      this.customDict.LocaleEnum.forEach(e => {
        localeList.push({"id": i, "locale": e.type, 'name': ''})
        i++;
      })
      this.form.localeList = localeList;
    },
    validatorLocaleName() {
      let errorMessage = "";
      var localeList = this.form.localeList;
      let localeEnum = this.customDict.LocaleEnum;
      localeList.forEach(e => {
        if (!e.name) {
          let localeName = localeEnum.find(locale => locale.type === e.locale).name;
          errorMessage += localeName + this.$t('商品.商品名称') + this.$t('通用.文本.不能为空');
        }
      })
      if (errorMessage) {
        this.errorMessage = errorMessage;
        return false;
      }
      this.errorMessage = '';
      return true;
    },
    validatorSku() {
      if (!this.form.skuList) {
        return true;
      }
      let message = "";
      this.form.skuList.forEach(e => {
        let skuErrorMessage = "";
        if (!e.skuCode) {
          skuErrorMessage += this.$t('商品.sku.编码') + this.$t('通用.文本.不能为空') + "; ";
        }
        if (e.price <= 0) {
          skuErrorMessage += this.$t('商品.sku.价格') + this.$t('通用.文本.不能小于0') + "; ";
        }
        if (e.activityPrice <= 0) {
          skuErrorMessage += this.$t('商品.sku.活动价格') + this.$t('通用.文本.不能小于0') + "; ";
        }
        if (e.stock <= 0) {
          skuErrorMessage += this.$t('商品.sku.库存') + this.$t('通用.文本.不能小于0') + "; ";
        }
        if (e.weight <= 0) {
          skuErrorMessage += this.$t('商品.sku.重量') + this.$t('通用.文本.不能小于0') + "; ";
        }
        message = skuErrorMessage
      })
      if (message) {
        this.skuErrorMessage = message;
        return false;
      }
      this.skuErrorMessage = '';
      return true;
    },

    validatorSpecValue(item) {
      let specErrorMessage = "";
      item.specValueList.forEach(e => {
        if (!e.specValueName) {
          specErrorMessage += this.$t('商品.规格.规格值') + this.$t('通用.文本.不能为空');
        }
      })
      if (specErrorMessage) {
        item.specErrorMessage = specErrorMessage;
        return false;
      }
      item.specErrorMessage = undefined;
      return true;
    },
    validatorSpecValueAll() {
      let result = true;
      this.form.specList.forEach(spec => {
        let specErrorMessage = "";
        spec.specValueList.forEach(e => {
          if (!e.specValueName) {
            specErrorMessage += this.$t('商品.规格.规格值') + this.$t('通用.文本.不能为空');
          }
        })
        if (specErrorMessage) {
          spec.specErrorMessage = specErrorMessage;
          result = false;
        } else {
          spec.specErrorMessage = undefined;
        }
      })

      return result;
    },


    /*text 要翻译的字段*/
    getTranslation(record) {
      if (!this.form.goodsName) {
        return
      }
      let data = {"text": this.form.goodsName, "locale": record.locale};
      translation(data).then(response => {
        record.name = response.data
      })
    },

    /*页面初始化时获取分类类别*/
    getCategoryList() {
      listCategory().then(response => {
        let children = this.handleTree(response.data, 'id', 'pid')
        console.log('children', children)
        this.categoryList = children
      })
    },

    /*页面初始化时获取标签类别*/
    getLabelList() {
      allLabel().then(response => {
        this.labelList = response.data;
      })
    },

    getStarName() {
      if (this.form.starId) {
        return this.form.starDetail.starName
      } else {
        return this.$t('通用.输入.请选择') + this.$t('商品.明星')
      }
    },

    getFreightName() {
      if (this.form.freightTemplateId && this.form.freightTemplateDetail) {
        return this.form.freightTemplateDetail.name
      } else {
        return this.$t('通用.输入.请选择') + this.$t('商品.运费模板')
      }
    },

    getBrandList() {
      allBrand().then(response => {
        this.brandList = response.data
      })
    },
    getPackageIdList() {
      listSys().then(response => {
        this.packageIdList = response.data
      })
    },
    /*分类的选择*/
    onCategoryChange(value, selectedOptions) {
      this.form.categoryId = value[1]
    },

    addSpec() {
      let validatorSpec = this.validatorSpecValueAll();
      if (!validatorSpec) {
        return;
      }
      const index = this.form.specList.length + 1;
      const newData = {
        id: index,
        specName: null,
        specDesc: null,
        isHaveImg: false,
        sort: index,
        specValueList: [],
      };
      this.form.specList.push(newData);
    },
    editSpec() {
      this.specDisable = false;
      /*是否发生了规格变动如果有则保存到后台时会先执行删除商品操作 后执行添加商品操作  如果没有则 直接修改*/
      this.isChangeSpec = true;
      //修改规格后,清空所有sku
      this.form.skuList = [];
    },
    delSpec(id) {
      this.form.specList.splice(id, 1)
      this.generateSkus();
    },

    addSpecValue(item) {
      let validatorSpecValue = this.validatorSpecValue(item);
      if (!validatorSpecValue) {
        return;
      }
      let index = item.id % 10000 * 100 + item.specValueList.length + 1;
      const newData = {
        index: index,
        id: index,
        specValueName: null,
        specName: item.specName,
        picture: null,
        isVisible: true,
        specErrorMessage: undefined,
        sort: 0,
      };
      item.specValueList.push(newData);
    },
    delSpecValue(item, record) {
      item.specValueList = item.specValueList.filter(e => e.id !== record.id);
      this.generateSkus();
    },

    /*判断是否可修改可见, 只剩最后一个规格值可用时不能修改*/
    getLimitOne(index, specValueList) {
      //不可用的list
      let visibleList = specValueList.filter(obj => obj.isVisible === true);
      //可见list里是否有自己
      let selfBoolean = visibleList.filter(obj => obj.index === index).length >= 1;
      //如果不可用的小于一个
      let count = visibleList.length;
      //如果可用的小于一个,并且自己还是可用的,返回disable
      return selfBoolean && count <= 1;
    },

    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.initLocale();
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate(id, copy) {
      this.reset()
      this.formType = 2
      this.specDisable = true;
      getGoods({"id": id}).then(response => {
        this.form = response.data
        //会显出轮播图
        this.imgList = [];
        var advertImgList = this.form.imgList == null ? [] : this.form.imgList;
        for (var i = 0; i < advertImgList.length; i++) {
          this.imgList.push({status: 'done', url: advertImgList[i], uid: this.getUidRandom(), name: advertImgList[i]})
        }
        //回显出分类
        this.categoryList.forEach(e => {
          if (e.children) {
            e.children.forEach(child => {
              if (child.id === this.form.categoryId) {
                this.categorySelected.push(e.id)
                this.categorySelected.push(child.id)
              }
            });
          }
        });
        if (copy) {
          this.formTitle = this.$t('通用.按钮.复制');
          this.form.id = null
        } else {
          this.formTitle = this.$t('通用.文本.修改');
        }
      })
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // oss 上传开始
    async handlePreview(file) {
      let suffix = file.name.substring(file.name.lastIndexOf('.'));
      if (suffix === '.mp4') {
        this.handleVideoPreview(file);
      } else {
        this.handleImagePreview(file);
      }
    },
    async handleVideoPreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewVideoVisible = true
    },
    async handleImagePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewImageVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 20
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!')
      }
      return isLt2M
    },
    // 上传覆盖默认的上传行为
    coverImgUpload(file, index) {
      debugger
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'goods'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.$set(_this.form, 'picture', res)
          _this.$message.success(this.$t('通用.文本.上传成功'),)
        })
      })
    },
    coverSpecValueImgUpload(file, record) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'goods'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          record.picture = res;
          _this.$message.success(this.$t('通用.文本.上传成功'),)
        })
      })
    },

    // oss 上传结束
    //多图上传开始
    getUidRandom() {
      return Math.round(Math.random() * 80 + 20)
    },
    // 上传详情覆盖默认的上传行为
    coverImgCaseUploadInfo(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'goods'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.imgList.push({status: 'done', url: res, uid: this.getUidRandom(), name: res})
          console.log(_this.imgList)
          this.form.imgList = []
          _this.imgList.forEach(e => {
            if (e.url !== undefined && e.url !== null && e.url !== '') {
              this.form.imgList.push(e.url);
            }
          })
          _this.$message.success('上传成功')
        })
      })
    },
    // 删除图片
    removeImgCaseFile(file) {
      var fileList = []
      this.imgList.forEach(e => {
        if (e.url !== file.url) {
          fileList.push(e)
        }
      })
      var imgList = []
      this.imgList = fileList
      fileList.forEach(e => {
        imgList.push(e.url)
      })
      this.form.imgList = imgList
    },
    //多图上传结束


    limitSort(sort) {
      console.log(sort)
      let val = sort;
      if (val == null || val == undefined || val == '') {
        val = 0
      }
      if (val > 999999) {
        val = 999999
      }
      this.form.sort = val
    },
    //品牌选择
    handleBrandSearch(value) {
      listBrand(({"name": value})).then(response => {
        this.brandList = response.data
      })
      // listBrand(({"name": value}))
    },
    handleBrandChange(value) {
      this.brandId = value.id;
      this.getBrandList()
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        /*let localeValid = this.validatorLocaleName();
        if (!localeValid) {
          return false;
        }*/

        let skuValid = this.validatorSku();
        if (!skuValid) {
          return false;
        }
        if (valid) {
          this.submitLoading = true;
          if (this.form.id !== undefined && this.form.id !== null) {
            this.form.isChangeSpec = this.isChangeSpec;
            updateGoods(this.form).then(response => {
              this.$message.success(
                this.$t('通用.文本.修改成功'),
                3
              )
              this.open = false
              this.$emit('ok')
              this.back();
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addGoods(this.form).then(response => {
              this.$message.success(
                this.$t('通用.文本.新增成功'),
                3
              )
              this.open = false
              this.$emit('ok')
              this.back();
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false;
        }
      });
    }
  }
}
</script>
<style lang="less">
.goods-collapse > .ant-collapse-header {
  color: #007ffd !important;
  background: #e8eef9;
}

.box {
  position: relative;
  background-color: #f7f7f7;
  padding: 0 15px 15px 15px;
  margin-bottom: 15px;
  border-radius: 5px;

  .delSpec {
    position: absolute;
    right: -5px;
    top: -5px;
    color: #f5222d;
    cursor: pointer;
  }
}

</style>